<template>
  <v-navigation-drawer
    touchless
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :class="{ 'no-extension-drawer': $vuetify.breakpoint.xsOnly }"
    temporary
    right
    v-model="open"
    class="pb-0" width="350"
    app
    clipped>
    <v-layout align-center class="pa-2">
      <v-flex>
        <span class="title ml-2">{{ title }}</span>
      </v-flex>
      <v-flex xs2>
        <v-btn color="grey darken-2" text icon dark @click="open = false; $emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-container style="height: calc(100% - 64px); overflow: auto;">
      <slot name="form"></slot>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    title: String,
    triggerOpen: Date,
    triggerClose: Date
  },
  data () {
    return {
      open: false
    }
  },
  watch: {
    triggerOpen () {
      this.open = true
    },
    triggerClose () {
      this.open = false
    }
  }
}
</script>
